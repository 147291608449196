import React, { useState, useEffect } from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoints from "utils/breakpoints"

// Components
import Button from "components/button/"
import Container from "components/container/"
import OpenJobCard from "components/job-card"

const StyledJobOpenings = styled.section`
  padding: 48px 0 60px 0;

  ${breakpoints.medium`
    padding: 120px 0 148px 0;
  `}

  .text-job-openings {
    margin-bottom: 74px;
  }

  .no-jobs-opening {
    margin-top: 74px;
    background: white;
    padding: 40px 16px;
    border-radius: 8px;

    ${breakpoints.large`
      padding: 80px 245px;
    `}
  }

  .no-jobs-opening {
    margin-top: 74px;
    background: white;
    padding: 40px 16px;
    border-radius: 8px;

    ${breakpoints.large`
      padding: 80px 245px;
    `}

    .button {
      margin-top: 32px;
    }
  }
`

const JobOpenings = () => {
  const [loading, setLoading] = useState(true)
  const [visibleJobs, setVisibleJobs] = useState(6)
  const [jobOpenings, setJobOpenings] = useState(null)

  useEffect(async () => {
    const data = await fetch(`/api/jobs`, {
      method: "GET",
    }).then((response) => response.json())

    // if (typeof data === "object") {
    //   data = [data]
    // }

    setJobOpenings(data)
    setLoading(false)
  }, [])
  return (
    <StyledJobOpenings id="jobs" className="bg--silver__pale">
      <Container>
        <h2 className="heading--h1 h1 maxi text-center">
          <span className="color--turquoise__primary">Join</span> Our Team
        </h2>

        <div className="row">
          {loading ? (
            <p>Loading...</p>
          ) : jobOpenings.length > 0 ? (
            <div className="jobs">
              <p className="text-job-openings text--large text-center">
                Current job openings
              </p>

              <div className="row">
                {jobOpenings.slice(0, visibleJobs).map((job) => (
                  <div className="col-12 col-sm-4" key={job.title}>
                    <OpenJobCard job={job} className="d-flex" />
                  </div>
                ))}
                {visibleJobs < jobOpenings.length && (
                  <div className="col-12 text-center">
                    <Button
                      type="button"
                      level="tertiary"
                      className="bg--silver__extra_pale"
                      onClick={() => setVisibleJobs(visibleJobs + 6)}
                    >
                      Load more
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="no-jobs-opening text-center">
              <p className="text--large">
                We’re sorry, we don’t currently have any open positions. Check
                <br /> back later or contact us if you have any questions.
              </p>
              <Button
                level="primary"
                type="link"
                to="/contact-us"
                className="button"
              >
                Contact us
              </Button>
            </div>
          )}
        </div>
      </Container>
    </StyledJobOpenings>
  )
}

export default JobOpenings
