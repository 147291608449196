import React from "react"

// Libraries
import styled from "styled-components"
import PropTypes from "prop-types"

// Icon
import IconNavigation from "assets/icons/icon-navigation-location.inline.svg"
import IconArrowRight from "assets/icons/icon-arrow-right--small.inline.svg"

// Utils
import { colors } from "utils/variables"
import breakpoint from "utils/breakpoints"
// import getSlug from "../../utils/functions/getSlug"

const StyledOpenJobCard = styled.div`
height: 100%;

.col-12 {
  margin-bottom: 24px;
  
  ${breakpoint.medium`
    padding: 0 28px;
    margin-bottom: 51px;
  `}
}

.row {
  margin-bottom: -24px;

  ${breakpoint.medium`
    padding: 0 -28px;
    margin-bottom: -51px;
  `}
}

.card__container {
    height: 100%;
    max-width: 450px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 20px;
    background-color: ${colors.white};

      ${breakpoint.medium`
      max-width: 100%;
      `}

    &:hover {
      filter: drop-shadow(2px 16px 40px rgba(110, 200, 205, 0.2));

      h2 {
        color: ${colors.turquoise__primary};
      }
    }

    .image {
      * {
        stroke: ${colors.turquoise__primary};
    }
  }

  .job__description {
    padding: 32px;
  }

  .job__link {
    width: 100%;
    padding: 32px 0 32px 32px;
    background-color: ${colors.silver__extra_pale};

    a {
      margin-right: 4px;
    }
  }`

const OpenJobCard = (props) => {
  const { job } = props
  const { title, city, board_code: boarcode } = job
  const slug = title
  console.log(slug)

  return (
    <StyledOpenJobCard>
      <a
        className="card__container d-flex flex-wrap"
        href={`https://arena.applytojob.com/apply/${boarcode}/${slug}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="job__description">
          <h2 className="h2 mb-3">{title}</h2>
          <div className="card__location d-flex align-items-center">
            <IconNavigation className="image mr-2" />
            <p className="">{city}</p>
          </div>
        </div>
        <div className="job__link d-flex align-items-center align-self-end">
          <p className="color--turquoise__primary">View Job</p>
          <IconArrowRight className="ms-1" />
        </div>
      </a>
    </StyledOpenJobCard>
  )
}

export default OpenJobCard

OpenJobCard.propTypes = {
  job: PropTypes.shape({
    title: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    boarcode: PropTypes.string.isRequired,
    board_code: PropTypes.string.isRequired,
  }).isRequired,
}
