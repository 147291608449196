import React from "react"

// Layout
import Layout from "layouts/layout-primary"
import { graphql, useStaticQuery } from "gatsby"

// Components
import SEO from "components/seo/"
import Hero from "sections/careers/hero"
import Culture from "sections/careers/culture"
import Benefits from "sections/careers/benefits"
import JobOpenings from "sections/careers/job-openings"

// Sections

const Careers = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "careers/career-background-hero.png" }) {
        childImageSharp {
          gatsbyImageData(height: 695, quality: 100)
        }
      }
    }
  `)
  return (
    <Layout showNewsletter={false}>
      <SEO title="Careers | Arena Analytics" />
      <Hero image={image} />
      <Culture />
      <Benefits />
      <JobOpenings />
    </Layout>
  )
}

export default Careers
