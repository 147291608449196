import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints"

// Components
import Container from "components/container"

// Images
import ImageNurture from "assets/images/careers/nurture.inline.svg"
import ImageQuality from "assets/images/careers/quality.inline.svg"
import ImageSavings from "assets/images/careers/savings.inline.svg"
import ImageTeamSatisfaction from "assets/images/careers/team-satisfaction.inline.svg"
import ImageTime from "assets/images/careers/time.inline.svg"

const StyledBenefits = styled.section`
  padding: 50px 0 84px 0;

  .column-margin {
    margin-bottom: 48px;

    ${breakpoint.medium`
    margin-bottom: 0;
    `}

    &--first {
      margin-bottom: 48px;

      ${breakpoint.medium`
    margin-bottom: 74px;
    `}
    }
  }

  h2 {
    margin-bottom: 38px;

    ${breakpoint.medium`
    margin-bottom: 0;
      font-size: 48px;
      line-height: 56px;
  `}
  }

  ${breakpoint.medium`
    padding: 163px 0 191px 0;
  `}

  .benefit {
    &__image {
      width: 128px;
      height: 128px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: 2px 16px 40px rgba(110, 200, 205, 0.3);
      // box-shadow: 2px 16px 40px rgba(110, 200, 205, 0.3);
    }
  }
`

const data = [
  {
    image: <ImageSavings className="image" />,
    title: "401k retirement account",
  },
  {
    image: <ImageQuality className="image" />,
    title: "Continuing education reimbursement",
  },
  {
    image: <ImageNurture className="image" />,
    title: "Full medical, dental, and vision insurance",
  },
  {
    image: <ImageTime className="image" />,
    title: "Flexible Paid Time Off",
  },
  {
    image: <ImageTeamSatisfaction className="image" />,
    title: "Employee bonus and equity plan",
  },
]

const Benefits = () => (
  <StyledBenefits>
    <Container>
      <div className="row justify-content-center justify-content-sm-start">
        <div className="col-12 col-sm-6 col-md-3">
          <h2 className="h1 text-center text-md-start">
            And we have great{" "}
            <span className="color--turquoise__primary">benefits</span>
          </h2>
        </div>

        <div className="col-12 col-sm-6 col-md-2 offset-md-2 column-margin--first">
          <div className="benefit text-center text-sm-start">
            <div className="benefit__image mb-4 mx-auto mx-sm-0">
              {data[0].image}
            </div>

            <p className="h3">{data[0].title}</p>
          </div>
        </div>

        <div className="col-12 col-sm-6 col-md-3 offset-md-2 column-margin--first">
          <div className="benefit text-center text-sm-start">
            <div className="benefit__image mb-4 mx-auto mx-sm-0">
              {data[1].image}
            </div>

            <p className="h3">{data[1].title}</p>
          </div>
        </div>

        <div className="col-12 col-sm-6 col-md-3 column-margin">
          <div className="benefit text-center text-sm-start">
            <div className="benefit__image mb-4 column-margin mx-auto mx-sm-0">
              {data[2].image}
            </div>

            <p className="h3">{data[2].title}</p>
          </div>
        </div>

        <div className="col-12 col-sm-6 col-md-2 offset-md-2 column-margin">
          <div className="benefit text-center text-sm-start">
            <div className="benefit__image mb-4 mx-auto mx-sm-0">
              {data[3].image}
            </div>

            <p className="h3">{data[3].title}</p>
          </div>
        </div>

        <div className="col-12 col-sm-6 col-md-2 offset-md-2 column-margin">
          <div className="benefit text-center text-sm-start">
            <div className="benefit__image mb-4 mx-auto mx-sm-0">
              {data[4].image}
            </div>

            <p className="h3">{data[4].title}</p>
          </div>
        </div>
      </div>
    </Container>
  </StyledBenefits>
)

export default Benefits
