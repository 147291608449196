import React from "react"

// Libraries
import styled from "styled-components"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import breakpoints from "utils/breakpoints"

const StyledCareersCard = styled.div`
  filter: drop-shadow(2px 16px 40px rgba(110, 200, 205, 0.2));

  .text-section {
    width: 100%;
    height: auto;
    padding: 32px;
    background: white;

    ${breakpoints.small`
      width: 40%;

    `}

    ${breakpoints.medium`
      width: 47%;
    `}

    ${breakpoints.large`
      padding: 40px 40px 70px 40px;
    `}
  }

  .img {
    width: 100%;
    height: 100%;

    ${breakpoints.small`
      max-width: 400px;
      border-radius: 0;
    `}

    ${breakpoints.medium`
      max-width: 480px;
    `}
  }
`

const CareersCard = (props) => {
  const { textBold, textNormal, image, reverse } = props

  return (
    <StyledCareersCard>
      <div className={reverse ? "d-sm-flex flex-sm-row-reverse" : "d-sm-flex"}>
        <div className="text-section">
          <p className="h2 font-weight--700 mb-2">{textBold}</p>
          <p className="text--large">{textNormal}</p>
        </div>
        <div>
          <GatsbyImage image={getImage(image)} className="img" alt="" />
        </div>
      </div>
    </StyledCareersCard>
  )
}

CareersCard.propTypes = {
  textBold: PropTypes.string.isRequired,
  textNormal: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
}

CareersCard.defaultProps = {
  reverse: false,
}

export default CareersCard
