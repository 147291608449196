import React from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"

// Utils
import { colors } from "utils/variables"
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import HeroArrow from "components/hero-arrow"
import Button from "components/button"
import BackgroundImage from "components/background-image/"

const StyledHero = styled(BackgroundImage)`
  width: 100%;
  height: calc(100vh - 62px - 58px);
  max-height: 695px;
  background-color: ${colors.navy__extra_dark};
  z-index: 1001;
  position: relative;

  &::before,
  &::after {
    background-position: bottom right !important;
    background-size: 100% auto !important;
  }

  ${breakpoint.small`
  height: calc(120vh);
  // max-height: 1200px;
  `}

  ${breakpoint.medium`
  padding-left: 50px;
  height: calc(100vh - 96px - 58px);
`}

${breakpoint.large`
padding-left: 0;
`}

  ${breakpoint.medium`
    &::before,
    &::after {
      background-size: auto 100% !important;
      background-position: right !important;
    }
  `}

  .hero__arrow {
    .arrow__illustration {
      svg {
        * {
          fill: ${colors.navy__extra_dark};
        }
      }
    }
  }

  .container {
    text-align: center;
    padding: 0 !important;

    ${breakpoint.medium`
    text-align: left`}
  }

  .header {
    margin: 0 auto;
    padding: 32px 18px 25px 18px;
    text-align: center;

    ${breakpoint.medium`
    margin: 0 0;
      padding: 103px 0 0 0;
      text-align: left !important;
  `}
  }

  .hero_image {
    with: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  .join_button {
    margin-top: 31px;
    text-aling: center;
    box-shadow: none !important;
  }
`

const Hero = (props) => {
  const { image } = props
  return (
    <StyledHero image={image} className="col-12 d-flex color--white">
      <Container className="container">
        <h1 className="header col-md-7 h1 maxi">
          A Collaborative Culture Driven by the Desire to do Meaningful Work
        </h1>
        <Button
          type="link"
          level="primary"
          className="button join_button"
          to="#jobs"
        >
          Join Our Team
        </Button>
      </Container>
      <HeroArrow link="#culture" className="hero__arrow" />
    </StyledHero>
  )
}

Hero.propTypes = {
  image: PropTypes.shape({}).isRequired,
}

export default Hero
