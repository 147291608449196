import React from "react"

// Libraries
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

// Utils
import { colors } from "utils/variables"

// Components
import Container from "components/container/"
import CareersCard from "components/careers-card"
import breakpoints from "utils/breakpoints"

// Backgrounds
import BackgroundTop from "assets/icons/careers/background-desktop-top.inline.svg"
import BackgroundMiddle from "assets/icons/careers/background-desktop-middle.inline.svg"
import BackgroundBottom from "assets/icons/careers/background-desktop-bottom.inline.svg"
import BackgroundMobile from "assets/icons/careers/background-mobile.inline.svg"
import BackgroundDesktop from "assets/icons/careers/background-desktop-1.inline.svg"

const StyledCulture = styled.section`
  background: ${colors.silver__pale};
  padding: 48px 0;
  position: relative;

  ${breakpoints.medium`
    padding: 154px 0px 142px 0px;
  `}

  .title {
    margin-bottom: 16px;

    ${breakpoints.medium`
      margin-bottom: 24px;
    `}
  }

  .background-top {
    display: none;
    position: absolute;

    ${breakpoints.large`
      display: block;
      max-width: 300px;
      left: 0;
      top: 15%;
    `}
  }

  .background-middle {
    display: none;
    position: absolute;
    ${breakpoints.large`
      display: block;
      max-width: 300px;
      right: 0;
      top: 30%;
    `}
  }

  .background-bottom {
    display: none;
    position: absolute;
    ${breakpoints.large`
      display: block;
      max-width: 300px;
      left: 0;
      top: 73%;
    `}
  }

  .background-mobile {
    position: absolute;
    top: 10%;
    ${breakpoints.small`
        display: none;
    `}
  }

  .background-desktop {
    display: none;
    ${breakpoints.large`
      display: block;
      position: absolute;
      top: 18%;
    `}
  }

  .careers {
    margin-top: 40px;
    ${breakpoints.medium`
      margin-top: 67px;
    `}

    .careers__card {
      margin-bottom: 40px !important;
      border-radius: 8px;
      overflow: hidden;

      &:last-of-type {
        margin-bottom: 0;
      }

      ${breakpoints.medium`
        margin-bottom: 112px !important;
        &:last-of-type{
          margin-bottom: 0;
        }
      `}
    }
  }
`

const Culture = () => {
  const imageData = useStaticQuery(graphql`
    query {
      firstImage: file(relativePath: { eq: "careers/card-image-1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100)
        }
      }
      secondImage: file(relativePath: { eq: "careers/card-image-2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100)
        }
      }
      thirdImage: file(relativePath: { eq: "careers/card-image-3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100)
        }
      }
      fourthImage: file(relativePath: { eq: "careers/card-image-4.png" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100)
        }
      }
      fifthImage: file(relativePath: { eq: "careers/card-image-5.png" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100)
        }
      }
      sixthImage: file(relativePath: { eq: "careers/card-image-6.png" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100)
        }
      }
    }
  `)

  const data = [
    {
      textBold:
        "We believe talent is equally distributed but opportunity is not,",
      textNormal:
        "and we think technology and data science can help play a part in solving that problem.",
      image: imageData.firstImage,
      reverse: false,
    },
    {
      textBold:
        "We believe it is important to do work that makes a real impact on people’s lives.",
      textNormal:
        "We love innovation and technology, and we want to put it to use to improve the world.",
      image: imageData.secondImage,
      reverse: true,
    },
    {
      textBold: "We foster a culture of intellectual curiosity",
      textNormal:
        "where everyone’s ideas are respected and open, respectful discussion and debate is encouraged, and we’re not afraid to make mistakes.",
      image: imageData.thirdImage,
      reverse: false,
    },
    {
      textBold: "We love to collaborate and ideate and make cool things,",
      textNormal:
        "and nothing makes us happier than seeing our customers benefit from the products we create.",
      image: imageData.fourthImage,
      reverse: true,
    },
    {
      textBold: "We believe in work/life balance–",
      textNormal:
        "we work very hard, but we understand and value the importance of finding meaning both inside and outside of work.",
      image: imageData.fifthImage,
      reverse: false,
    },
    {
      textBold: "We are a remote but very connected workforce,",
      textNormal:
        "augmenting daily video calls and slack updates with occasional in-person get togethers and company events.",
      image: imageData.sixthImage,
      reverse: true,
    },
  ]

  return (
    <StyledCulture id="culture">
      <Container>
        <div className="row justify-content-center g-0">
          <BackgroundTop className="background-top" />
          <BackgroundMiddle className="background-middle" />
          <BackgroundBottom className="background-bottom" />
          <BackgroundMobile className="background-mobile" />
          <BackgroundDesktop className="background-desktop" />

          <div className="col-12 col-md-10 text-center">
            <h2 className="h1 title">
              First and foremost, everyone who works at Arena shares a few
              fundamental beliefs and characteristics.
            </h2>
          </div>

          <div className="row g-0">
            <div className="careers">
              {data.map((card, index) =>
                index % 2 === 0 ? (
                  <div className="col-12 col-lg-9 ms-auto">
                    <div className="careers__card">
                      <CareersCard {...card} />
                    </div>
                  </div>
                ) : (
                  <div className="col-12  col-lg-9">
                    <div className="careers__card">
                      <CareersCard {...card} />
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </Container>
    </StyledCulture>
  )
}

export default Culture
